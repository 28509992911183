import React, { useState, useRef, useContext, useEffect } from "react";
import {
  makeStyles,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  Paper,
  Button,
  TextField,
  Divider,
  Grid,
  IconButton,
  Drawer,
  Input,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// import Header from "../../components/navbars/topNavbar";
import withAllContexts from "../../HOCs/withAllContexts";
import { v4 as uuidv4 } from "uuid";
// import { LanguageContext } from "../../contexts";
// import { MTableEditRow } from "material-table";
import axios from "axios";
import { config } from "../../config";
// import Alert from "../../components/alert";
import { ReactTransliterate } from "react-transliterate";
import "react-transliterate/dist/index.css";
// import { isDefinitionNode } from "graphql";
import Dialog from "../../components/dialog/index";
// import CircularProgress from "@material-ui/core/CircularProgress";
import {
  createDocument,
  deleteDocument,
  // deleteDocument,
  updateDocument,
} from "../../apis/crud";
// import isEqual from "react-fast-compare";
import { StateContext } from "../../contexts/index";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toBeRequired } from "@testing-library/jest-dom/dist/matchers";
import { useDebounce } from "../../utils";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const filter = createFilterOptions();
// import { findByDisplayValue } from "@testing-library/react";
const indexvalue = {
  0: "Language",
  1: "Country",
  2: "Date Time Format",
};
const keyvaluesforCountryTAb = [
  "countryName",
  "countryCode",
  "currencyName",
  "currencySymbol",
  "timeZone",
];
const useStyles = makeStyles((theme) => ({
  root: {
    background: "#f4f4f5",
  },
  container: {
    padding: "25px",
  },
  tableRow: {
    height: 50,
  },
  tabAlign: {
    display: "flex",
    padding: "5px",
    margin: "12px 6px",
    alignItems: "center",
  },
  drawerContainer: {
    padding: "18px",
    width: "560px",
  },
  drawerContent: {
    paddingTop: "16px",
  },
  mctitle: {
    "& .MuiFormControl-marginNormal": {
      margin: "0px",
    },
  },
  buttonforcheck: {
    "& .MuiButton-outlinedSizeSmall": {
      fontSize: "0.5rem",
    },
    "& .MuiButton-root": {
      width: "40px",
    },
  },
}));

function MessageCatalog(props) {
  const classes = useStyles();
  const context = useContext(StateContext);
  const [from, setFrom] = useState("en");
  const [titleSearch, setTitleSearch] = useState(null);
  const [titleLoading, setTitleLoading] = useState(false);
  const [messageCatalogOptions, setMessageCatalogOptions] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);

  const lazyTitleSearch = useDebounce(titleSearch, 500);

  const langFields = useRef({});
  React.useEffect(() => {}, [langFields]);

  const drawerClosing = () => {
    context.setState({ ...context, isdrawerOpen: false, fieldChange: {} });
  };
  const drawerOpen = (isEdit, messageCatalog) => {
    if (props.value === 0) {
      let fields = {};
      if (isEdit) {
        context?.languages.map((field) => {
          fields[field.titles] =
            field.titles === "title"
              ? messageCatalog.title
              : messageCatalog.language[field.titles];
        });
        setIsPrivate(messageCatalog.private);
      } else {
        context?.languages.map((field) => {
          fields[field.titles] = "";
        });
      }

      context.setState({
        ...context,
        fieldChange: fields,
        isdrawerOpen: true,
        isEdit: isEdit,
      });
    } else {
      context.setState({
        ...context,
        isdrawerOpen: true,
      });
    }
  };

  const fieldChanging = (data) => {
    const fieldName = data.name;
    const fieldValue = data.value;
    let result = {
      ...context.fieldChange,
      [fieldName]: fieldValue,
    };
    if (fieldName === "title" && Object.keys(result).includes("English")) {
      result["English"] = fieldValue;
    }
    context.setState({
      ...context,
      fieldChange: {
        ...result,
      },
    });
  };

  const onAdd = async () => {
    if (props.value === 0) {
      if (Object.values(context.fieldChange).some((value) => !value)) {
        return props.alert.setSnack({
          open: true,
          msg: "Please Fill all the data",
          severity: "warning",
        });
      } else {
        let payload = {};
        let newMessageCatalog = {};
        if (typeof context.fieldChange.title === "string") {
          if (
            context.messageCatalogs.some(
              (mc) =>
                mc.title.trim().toLowerCase() ===
                context.fieldChange.title.trim().toLowerCase()
            )
          ) {
            return props.alert.setSnack({
              open: true,
              msg: `${context.fieldChange.title} is already created, please check`,
              severity: "info",
            });
          }
          let { title, ...rest } = context.fieldChange;
          newMessageCatalog = {
            projectid: [context.projectDetails.projectid],
            id: uuidv4(),
            language: {
              ...rest,
            },
            private: isPrivate,
            title: title,
          };
          payload = {
            entity: "message_catalog",
            body: newMessageCatalog,
          };
          await createDocument([payload])
            .then(async (result) => {
              context.setState({
                ...context,
                messageCatalogs: [
                  ...context.messageCatalogs,
                  newMessageCatalog,
                ],
                fieldChange: {},
                isdrawerOpen: false,
              });
              props.alert.setSnack({
                open: true,
                msg: "Message catalog added successfully",
                severity: "success",
              });
            })
            .catch((err) => {
              console.error("Failed to do the operations");
              console.error(err);
              props.alert.setSnack({
                open: true,
                msg: "Message catalog add faild !",
                severity: "error",
              });
            });
        } else {
          let { title, ...rest } = context.fieldChange;
          console.log(title, title.title.trim().toLowerCase());
          if (
            context.messageCatalogs.some(
              (mc) =>
                mc.title.trim().toLowerCase() ===
                title.title.trim().toLowerCase()
            )
          ) {
            return props.alert.setSnack({
              open: true,
              msg: `${context.fieldChange.title.title} is already created, please check`,
              severity: "info",
            });
          }
          newMessageCatalog = {
            ...title,
            projectid: [...title.projectid, context.projectDetails.projectid],
            language: {
              ...title.language,
              ...rest,
            },
          };
          payload = {
            entity: "message_catalog",
            body: newMessageCatalog,
            filter: {
              id: title.id,
            },
          };
          await updateDocument([payload])
            .then(async (result) => {
              context.setState({
                ...context,
                messageCatalogs: [
                  ...context.messageCatalogs,
                  newMessageCatalog,
                ],
                isdrawerOpen: false,
              });
              props.alert.setSnack({
                open: true,
                msg: "Message catalog added successfully",
                severity: "success",
              });
            })
            .catch((err) => {
              console.error("Failed to do the operations");
              console.error(err);
              props.alert.setSnack({
                open: true,
                msg: "Message catalog add faild !",
                severity: "error",
              });
            });
        }
      }
    } else {
      if (context.countryValue) {
        context.setState({
          ...context,
          isdrawerOpen: false,
          country: [...context.country, context.countryValue],
        });
      } else {
        props.alert.setSnack({
          open: true,
          msg: "Please choose a country",
          severity: "info",
        });
      }
    }
  };

  const onUpdate = async () => {
    if (props.value === 0) {
      if (Object.values(context.fieldChange).some((value) => !value)) {
        return props.alert.setSnack({
          open: true,
          msg: "Please Fill all the data",
          severity: "warning",
        });
      } else {
        let messageCatalogsCopy = JSON.parse(
          JSON.stringify(context.messageCatalogs)
        );
        let { title, ...rest } = context.fieldChange;
        let updatedMessageCatalog = {};
        messageCatalogsCopy.forEach((messageCatalog, index, array) => {
          if (messageCatalog.id === context.isEdit) {
            updatedMessageCatalog = {
              ...messageCatalog,
              language: {
                ...messageCatalog.language,
                ...rest,
              },
            };
            array[index] = updatedMessageCatalog;
          }
        });
        let payload = {
          entity: "message_catalog",
          body: updatedMessageCatalog,
          filter: {
            id: updatedMessageCatalog.id,
          },
        };
        await updateDocument([payload])
          .then(async (result) => {
            context.setState({
              ...context,
              messageCatalogs: messageCatalogsCopy,
              fieldChange: {},
              isdrawerOpen: false,
            });
            props.alert.setSnack({
              open: true,
              msg: "Message catalog added successfully",
              severity: "success",
            });
          })
          .catch((err) => {
            console.error("Failed to do the operations");
            console.error(err);
            props.alert.setSnack({
              open: true,
              msg: "Message catalog add faild !",
              severity: "error",
            });
          });
      }
    } else {
      if (context.countryValue) {
        context.setState({
          ...context,
          isdrawerOpen: false,
          country: [...context.country, context.countryValue],
        });
      } else {
        props.alert.setSnack({
          open: true,
          msg: "Please choose a country",
          severity: "info",
        });
      }
    }
  };

  const deleteRow = async (id) => {
    if (props.value === 0) {
      let updatedSchema = context.messageCatalogs.find(
        (mc) => mc.id === context.deleteId
      );
      updatedSchema = {
        ...updatedSchema,
        projectid: updatedSchema.projectid.filter(
          (id) => id !== localStorage.projectid
        ),
      };
      let payload = {
        entity: "message_catalog",
        body: updatedSchema,
        filter: {
          id: updatedSchema.id,
        },
      };
      await updateDocument([payload])
        .then(async (result) => {
          const newRow = context.messageCatalogs.filter((itm) => itm.id !== id);
          context.setState({
            ...context,
            deleteDrawer: false,
            messageCatalogs: newRow,
            deleteId: "",
          });
          props.alert.setSnack({
            open: true,
            msg: "Message catalog deleted successfully",
            severity: "success",
          });
        })
        .catch((err) => {
          console.error("Failed to do the operations");
          console.error(err);
          props.alert.setSnack({
            open: true,
            msg: "Message catalog add faild !",
            severity: "error",
          });
        });
    } else {
      const newRow = context.country.filter((v) => v !== context.countryValue);
      context.setState({ ...context, deleteDrawer: false, country: newRow });
    }
  };

  const handleDelete = (id) => {
    if (props.value === 0) {
      context.setState({ ...context, deleteDrawer: true, deleteId: id });
    } else {
      context.setState({ ...context, deleteDrawer: true, countryValue: id });
    }
  };

  const handleDeleteDrawerClose = async () => {
    context.setState({
      ...context,
      deleteId: "",
      deleteDrawer: false,
    });
  };

  const closeIsDrawerOpen = () => {
    context.setState({ ...context, isdrawerOpen: false });
  };

  const handleapiforlanguage = async (v, text) => {
    const params = new URLSearchParams();
    params.append("q", text);
    params.append("source", from);
    params.append("target", v.code.trim() === "" ? "en" : v.code);
    params.append("api_key", "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx");
    let result = await axios
      .post("https://libretranslate.de/translate", params, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        debugger;
        return res.data.translatedText;
      })
      .catch(() => {
        return "not supported";
      });
    return result;
  };

  const handlecheck = async (language) => {
    props.backDrop.setBackDrop({
      open: true,
      message: "loading",
    });
    var text =
      typeof context.fieldChange.title === "string"
        ? context.fieldChange.title
        : context.fieldChange.title.title;
    let result = await handleapiforlanguage(language, text);
    if (result === "not supported") {
      props.alert.setSnack({
        open: true,
        msg: "Auto translation is not supported for this language, please add manually",
        severity: "warning",
      });
      setTimeout(() => {
        props.alert.onclose();
      }, 5000);
    } else {
      fieldChanging({
        name: language.titles,
        value: result,
      });
    }

    props.backDrop.setBackDrop({
      open: false,
      message: "loading",
    });
  };

  const handleChangeforAutocomplete = (e, option) => {
    debugger;
    context.setState({
      ...context,
      countryValue: option?.id,
    });
  };

  const getMatchedTitle = async () => {
    await axios
      .post(`${config.arango_api_url}/read_documents`, {
        db_name: `${config.database}`,
        entity: "message_catalog",
        filter: `LIKE(message_catalog.title,'%${lazyTitleSearch}%',true) AND message_catalog.private == false AND message_catalog.activestatus == true`,
        return_fields: "message_catalog",
      })
      .then((response) => {
        if (response.data.result.length > 0) {
          let resultData = response.data.result;
          setMessageCatalogOptions(resultData);
        }
        setTitleLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setTitleLoading(false);
      });
  };

  useEffect(() => {
    if (
      lazyTitleSearch?.length > 0 &&
      !context.fieldChange.title &&
      !context.isEdit
    ) {
      setTitleLoading(true);
      getMatchedTitle();
    }
  }, [lazyTitleSearch]);

  useEffect(() => {
    if (!context.fieldChange.title) {
      setMessageCatalogOptions([]);
    }
  }, [context.fieldChange]);

  console.log(context);

  return (
    <div style={{ marginBottom: "126px" }}>
      <Dialog
        open={context.deleteDrawer}
        handleClose={() => handleDeleteDrawerClose()}
        component={
          <div>
            <div style={{ padding: "10px", textAlign: "center" }}>
              <Typography
                className={classes.title}
                style={{ fontSize: "1.2rem" }}
              >
                Are you sure, you want to delete?
              </Typography>
            </div>
            <div
              style={{
                textAlign: "center",
                marginTop: "15px",
                padding: "10px",
              }}
            >
              <Button
                variant="contained"
                style={{ marginRight: "10px" }}
                onClick={() => handleDeleteDrawerClose()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => deleteRow(context.deleteId)}
              >
                Submit
              </Button>
            </div>
          </div>
        }
      />
      <div className={classes.cgontainer}>
        <div className={classes.tabAlign}>
          <Typography style={{ fontSize: "17px", flexGrow: 1 }}></Typography>
          <Button
            onClick={(e) => drawerOpen(false)}
            variant="contained"
            style={{ marginRight: "0px" }}
          >
            Add message catalog
          </Button>
          {/* <Button
            color="primary"
            variant="contained"
            onClick={() => saveMessageCatalogs()}
          >
            Save
          </Button> */}
        </div>
        <Paper>
          {context.languages.length > 1 && (
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {props.value === 0 ? (
                      context.languages.map((itm, i) => (
                        <TableCell
                          style={{
                            // width: "200px",
                            textTransform: "capitalize",
                          }}
                          key={i}
                        >
                          {itm.titles}
                        </TableCell>
                      ))
                    ) : props.value === 1 ? (
                      keyvaluesforCountryTAb.map((v) => {
                        return (
                          <TableCell
                            align="left"
                            style={{ textTransform: "capitalize" }}
                          >
                            {v}
                          </TableCell>
                        );
                      })
                    ) : (
                      <></>
                    )}
                    <TableCell align="left">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.value === 0 && (
                    <>
                      {context.messageCatalogs.map((messageCatalog) => {
                        return (
                          <TableRow
                            className={classes.tableRow}
                            key={messageCatalog.id}
                          >
                            <TableCell key={messageCatalog.id}>
                              {messageCatalog.title}
                            </TableCell>
                            {context.languages.map((lng, i) => {
                              if (lng.titles === "title") return null;
                              return (
                                <TableCell key={i}>
                                  {messageCatalog.language[lng.titles] ??
                                    "No data"}
                                </TableCell>
                              );
                            })}
                            <TableCell align="left" style={{}}>
                              <div style={{ marginLeft: "-18px" }}>
                                <IconButton
                                  onClick={(e) => {
                                    drawerOpen(
                                      messageCatalog.id,
                                      messageCatalog
                                    );
                                  }}
                                >
                                  <EditIcon></EditIcon>
                                </IconButton>
                                <IconButton
                                  onClick={(e) =>
                                    handleDelete(messageCatalog.id)
                                  }
                                >
                                  <HighlightOffIcon></HighlightOffIcon>
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  )}
                  {props.value === 1 &&
                    context.countryList.map((k) => {
                      return (
                        <TableRow>
                          {context.country.map((v, i) => {
                            return (
                              <>
                                {keyvaluesforCountryTAb.map((e, i) => {
                                  return (
                                    k.id === v && (
                                      <>
                                        <TableCell
                                          style={{ minwidth: "200px" }}
                                        >
                                          {k[e]}
                                        </TableCell>
                                        {keyvaluesforCountryTAb.length - 1 ===
                                          i && (
                                          <IconButton
                                            onClick={(e) => handleDelete(v)}
                                          >
                                            <HighlightOffIcon></HighlightOffIcon>
                                          </IconButton>
                                        )}
                                      </>
                                    )
                                  );
                                })}
                              </>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  {props.value === 2 && (
                    <TableRow>
                      <TableCell style={{ width: "100%" }}>
                        {" "}
                        <Typography>This feature is not implemented</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      </div>
      {/* ----------------------------------- Drawer ----------------------------------------------------- */}
      <Drawer
        open={context.isdrawerOpen}
        onClose={() => closeIsDrawerOpen()}
        anchor="right"
      >
        <div className={classes.drawerContainer}>
          <Typography
            style={{ fontSize: "18px", fontWeight: "500", padding: "10px 0px" }}
          >
            {context.isEdit ? "Edit" : "Add"} {indexvalue[props.value]}{" "}
            preferences
          </Typography>
          <Divider></Divider>
          {props.value === 0 ? (
            <>
              {Object.keys(context.fieldChange)
                .filter((v) => v !== "id")
                .map((item, i) => (
                  <div className={classes.drawerContent}>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <div style={{ display: "flex", padding: "10px 0 0 0" }}>
                          <Typography>{item}</Typography>
                          <div style={{ flexGrow: 1 }}></div>
                          <Typography>:</Typography>
                        </div>
                      </Grid>
                      <Grid item xs={9}>
                        {item === "title" ? (
                          <Autocomplete
                            size="small"
                            loading={titleLoading}
                            value={context?.fieldChange[item] ?? ""}
                            onChange={(event, newValue) => {
                              if (context.isEdit) {
                                return props.alert.setSnack({
                                  open: true,
                                  msg: "Title cannot be edited! if you want to update it, remove it and add new",
                                  severity: "warning",
                                });
                              }
                              if (typeof newValue === "string") {
                                fieldChanging({
                                  name: item,
                                  value: newValue,
                                });
                                setTitleSearch("");
                              } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                fieldChanging({
                                  name: item,
                                  value: newValue.inputValue,
                                });
                                setTitleSearch("");
                              } else {
                                if (newValue) {
                                  let fieldChangeCopy = JSON.parse(
                                    JSON.stringify({
                                      ...context.fieldChange,
                                      [item]: newValue,
                                    })
                                  );
                                  Object.entries(newValue.language).map(
                                    (mc) => {
                                      if (
                                        Object.keys(fieldChangeCopy).some(
                                          (key) => key === mc[0]
                                        )
                                      ) {
                                        fieldChangeCopy[mc[0]] = mc[1];
                                      }
                                    }
                                  );
                                  context.setState({
                                    ...context,
                                    fieldChange: fieldChangeCopy,
                                  });
                                  setTitleSearch("");
                                } else {
                                  fieldChanging({
                                    name: item,
                                    value: newValue,
                                  });
                                }
                              }
                            }}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);
                              // Suggest the creation of a new value
                              if (
                                params.inputValue !== "" &&
                                !titleLoading &&
                                lazyTitleSearch &&
                                !context.isEdit &&
                                !filtered.some(
                                  (mc) =>
                                    mc.title.toLowerCase() ===
                                    params?.inputValue.toLowerCase()
                                )
                              ) {
                                filtered.push({
                                  inputValue: params.inputValue,
                                  title: `Add "${params.inputValue}"`,
                                });
                              }

                              return filtered;
                            }}
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            id="free-solo-with-text-demo"
                            options={messageCatalogOptions}
                            getOptionLabel={(option) => {
                              // Value selected with enter, right from the input
                              if (typeof option === "string") {
                                return option;
                              }
                              // Add "xxx" option created dynamically
                              if (option.inputValue) {
                                return option.inputValue;
                              }
                              // Regular option
                              return option.title;
                            }}
                            renderOption={(option) => option.title}
                            // style={{ width: 300 }}
                            freeSolo
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                onChange={(e) => {
                                  if (context.isEdit) {
                                    return props.alert.setSnack({
                                      open: true,
                                      msg: "Title cannot be edited! if you want to update it, remove it and add new",
                                      severity: "warning",
                                    });
                                  }
                                  setTitleSearch(e.target.value);
                                }}
                              />
                            )}
                          />
                        ) : (
                          <ReactTransliterate
                            value={context?.fieldChange[item] ?? ""}
                            onChangeText={(text) => {
                              fieldChanging({
                                name: item,
                                value: text,
                              });
                            }}
                            lang={
                              context.languages.find(
                                (lng) => lng.titles === item
                              ).code ?? ""
                            }
                            renderComponent={(props) => {
                              const inputRef = props.ref;
                              delete props["ref"];
                              return (
                                <Input
                                  {...props}
                                  fullWidth={true}
                                  inputRef={inputRef}
                                  placeholder={"Add your message"}
                                  endAdornment={
                                    item !== "English" && (
                                      <Button
                                        color="primary"
                                        variant="outlined"
                                        size="small"
                                        style={{
                                          fontSize: "10px",
                                          margin: "10px 0px",
                                          // width: "50px !important",
                                        }}
                                        onClick={() => {
                                          if (!context.fieldChange.title)
                                            return;
                                          let language = context.languages.find(
                                            (lng) => lng.titles === item
                                          );
                                          handlecheck(language);
                                        }}
                                      >
                                        Translate
                                      </Button>
                                    )
                                  }
                                />
                              );
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </div>
                ))}
              {/* {context.fieldChange.title &&
                typeof context.fieldChange.title === "string" && (
                  <div style={{ margin: "24px 0px", display: "flex" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isPrivate}
                          onChange={(e) => setIsPrivate(e.target.checked)}
                        />
                      }
                      label="Private"                            
                    />
                  </div>
                )} */}
            </>
          ) : props.value === 1 ? (
            <div className={classes.drawerContent}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <div style={{ display: "flex", padding: "10px 0 0 0" }}>
                    <Typography style={{ textTransform: "capitalize" }}>
                      Country
                    </Typography>
                    <div style={{ flexGrow: 1 }}></div>
                    <Typography>:</Typography>
                  </div>
                </Grid>
                <Grid item xs={8} style={{ margin: "auto" }}>
                  <Autocomplete
                    size="small"
                    style={{ width: "190px" }}
                    // value={context.countryValue ?? null}
                    // fullWidth
                    onChange={(e, options) =>
                      handleChangeforAutocomplete(e, options)
                    }
                    options={context.countryList}
                    getOptionLabel={(option) => option?.countryName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Country"}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </div>
          ) : (
            <Typography>This Feature is not yet implemented</Typography>
          )}
          <div
            style={{
              width: "100%",
              // margin: "auto",
              marginTop: "32px",
              textAlign: "end",
            }}
          >
            <Button
              variant="contained"
              onClick={drawerClosing}
              style={{ marginRight: "10px" }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={context.isEdit ? onUpdate : onAdd}
            >
              {context.isEdit ? "Update" : "Add"}
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default withAllContexts(MessageCatalog);
