import axios from "axios";
import React, { useState } from "react";
import { useLayoutEffect } from "react";
import { config } from "./config";
import { StateContext } from "./contexts";
import withAllContexts from "./HOCs/withAllContexts";

function Appstate(props) {
  const [state, setState] = useState({
    allmsgrecord: [],
    country: [],
    countryValue: "",
    messageCatalogs: [],
    languages: [{}],
    projectDetails: {
      projectid: "",
      clientid: "",
    },
    isdrawerOpen: false,
    isEditOpen: {
      field: "",
      isOpen: false,
    },
    editedLanguage: {},
    messageAlert: {
      isopen: false,
      snackmsg: "client save successfully",
      snacktype: "success",
    },
    tabValue: 0,
    deleteState: {},
    deleteDrawer: false,
    loader: true,
    tableHeader: [],
    fieldChange: {},
    editcount: Boolean(),
    countryList: [],
    options: [],
  });

  useLayoutEffect(() => {
    debugger;
    if (window.location.search && window.location.search.length > 0) {
      let params = new URLSearchParams(window.location.search);
      localStorage.setItem("metadataId", params.get("metadata_id"));
      localStorage.setItem("projectName", params.get("projectName"));
    }

    getlanguage();
    console.log("afterOne", state);
    // getMessageLog();
    // getMessageLog() is called inside a getlanguage(), bcos of stateupdate
  }, []);

  const getlanguage = async () => {
    debugger;
    props.backDrop.setBackDrop({
      open: true,
      message: "loading",
    });
    await axios
      .post(`${config.arango_api_url}/read_documents`, {
        db_name: `${config.database}`,
        entity: "projectvstools",
        filter: `projectvstools.metadataid=='${localStorage.getItem(
          "metadataId"
        )}'`,
        return_fields:
          "merge(keep(projectvstools,'projectid','clientid'),{projectlang:document(document(projectvstools.projectid).projectlang)})",
      })
      .then(async (response) => {
        debugger;
        if (response.data.result[response.data.result.length - 1].projectlang) {
          let selectLanguage =
            response.data.result[response.data.result.length - 1].projectlang;
          let prodetail = {
            clientid:
              response.data.result[response.data.result.length - 1].clientid ??
              "",
            projectid:
              response.data.result[response.data.result.length - 1].projectid ??
              "",
          };

          localStorage.setItem(
            "projectid",
            response.data.result[response.data.result.length - 1].projectid ??
              ""
          );
          localStorage.setItem(
            "clientid",
            response.data.result[response.data.result.length - 1].clientid ?? ""
          );

          let generateJSON = [];
          generateJSON.push({
            titles: "title",
            // placeholder: "Add your title",
            // label: "language",
            // minWidth: "180px",
            code: "",
          });

          selectLanguage.map((data) => {
            return generateJSON.push({
              titles: data.description,
              // placeholder: "Add the message",
              // label: "language",
              // minWidth: "180px",
              code: data.code,
            });
          });
          console.log("generateJSON", generateJSON);
          await getMessageLog(prodetail, generateJSON);
          //sending details as parameters for state updation
        }
      })
      .catch((err) => {
        props.alert.setSnack({
          open: true,
          msg: "Something Went Wrong",
          severity: "error",
        });
      });
  };

  const getMessageLog = async (prodetail, generateJSON) => {
    props.backDrop.setBackDrop({
      open: true,
      message: "loading",
    });
    debugger;
    var countryList = [];
    var translanguages = [];

    //For checking supported languages of libretranslate

    // await axios
    //   .get("https://libretranslate.de/languages", {
    //     headers: { accept: "application/json" },
    //   })
    //   .then((res) => {
    //     translanguages = res.data;
    //     console.log("translanguages-1", translanguages);
    //   });
    // console.log("translanguages", translanguages);

    // // Getting Country list from collection country
    // await axios
    //   .post(`${config.arango_api_url}/read_documents`, {
    //     db_name: `${config.database}`,
    //     entity: "country",
    //     return_fields: "country",
    //   })
    //   .then((res) => {
    //     if (res.data.result.length > 0) {
    //       countryList = res.data.result;
    //     }
    //     console.log("countryList", countryList);
    //   });

    // //getting messagelog from collection Localization
    // await axios
    //   .post(`${config.arango_api_url}/read_documents`, {
    //     db_name: `${config.database}`,
    //     entity: `Localization`,
    //     filter: `Localization.projectid == '${localStorage.getItem(
    //       "projectid"
    //     )}' && Localization.activestatus==true`,
    //     return_fields: `Localization`,
    //   })
    //   .then((response) => {
    //     // debugger;
    //     if (response.data.result.length > 0) {
    //       let resultData = response.data.result;
    //       console.log(resultData, "resultData");
    //       setState({
    //         ...state,
    //         projectDetails: prodetail,
    //         allmsgrecord: resultData[0],
    //         languageDetails: resultData[0]?.language,
    //         country: resultData[0].country,
    //         languages: generateJSON,
    //         editcount: resultData[0]?.language.length > 0 ? true : false,
    //         countryList: countryList,
    //         options: translanguages,
    //       });
    //     } else {
    //       setState({
    //         ...state,
    //         projectDetails: prodetail,
    //         languages: generateJSON,
    //         editcount: false,
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     props.alert.setSnack({
    //       open: true,
    //       msg: "Something Went Wrong",
    //       severity: "error",
    //     });
    //   });

    //GET MESSAGE CATALOGS

    await axios
      .post(`${config.arango_api_url}/read_documents`, {
        db_name: `${config.database}`,
        entity: `message_catalog`,
        filter: `message_catalog.projectid any == '${prodetail.projectid}' AND message_catalog.activestatus == true`,
        return_fields: "message_catalog",
      })
      .then((response) => {
        console.log(response);
        if (response.data.result.length > 0) {
          let resultData = response.data.result;
          console.log(resultData, "resultData");
          setState({
            ...state,
            projectDetails: prodetail,
            messageCatalogs: resultData,
            languages: generateJSON,
          });
        } else {
          setState({
            ...state,
            projectDetails: prodetail,
            languages: generateJSON,
            messageCatalogs: [],
            // editcount: false,
          });
        }
      })
      .catch((err) => {
        props.alert.setSnack({
          open: true,
          msg: "Something Went Wrong",
          severity: "error",
        });
      });

    props.backDrop.setBackDrop({
      open: false,
      message: "",
    });
  };
  console.log("Final State", state);
  return (
    <StateContext.Provider
      value={{
        ...state,
        setState: setState,
      }}
    >
      {props.children}
    </StateContext.Provider>
  );
}

export default withAllContexts(Appstate);
