import React, { useState } from "react";
import { makeStyles, Tabs, Tab, Box } from "@material-ui/core";
import Header from "../../components/navbars/topNavbar";
import withAllContexts from "../../HOCs/withAllContexts";
import MessageCatalog from "../messageCatalog/messagecatalog";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    background: "#f4f4f5",
  },
  container: {
    padding: "25px",
  },
  tableRow: {
    height: 50,
  },
  tabAlign: {
    display: "flex",
    paddingBottom: "16px",
  },
  drawerContainer: {
    padding: "18px",
  },
  drawerContent: {
    paddingTop: "16px",
  },
}));

function MainTab(props) {
  const classes = useStyles();
  const messageHeaderName = "QDM";
  const [tabValue, settabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    console.log(newValue);
    settabValue(newValue);
  };
  const tabs = [0, 1, 2];
  return (
    <div className={classes.root}>
      <Header headerName={messageHeaderName}></Header>
      <div>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Message Catalog" />
          <Tab label="Country" />
          <Tab label="Date Time Format" />
        </Tabs>
        {tabs.map((v) => {
          return (
            <TabPanel value={tabValue} index={v} style={{ overflow: "auto" }}>
              <MessageCatalog value={v} />
            </TabPanel>
          );
        })}
      </div>
    </div>
  );
}

export default withAllContexts(MainTab);
